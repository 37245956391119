// TODO: Make these env configurable
export const APP_URL = 'https://glitch.com';
export const API_URL = 'https://api.glitch.com/';
export const EDITOR_URL = 'https://glitch.com/edit/';
export const CDN_URL = 'https://cdn.glitch.global';
export const PROJECTS_DOMAIN = 'glitch.me';

// TODO: Have keys for dev/staging/production when that gets set up
export const OPTIMIZELY_KEY = 'P2j9sPCvB8XVVpwmJfmG1';
export const OPTIMIZELY_URL =
  'https://cdn.optimizely.com/datafiles/P2j9sPCvB8XVVpwmJfmG1.json';
